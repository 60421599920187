import * as Styled from "./CompleteAdBanner.styles";

import GoldCardImg from "@vendor/assets/images/gold-card.png";
import PlatinumCardImg from "@vendor/assets/images/platinum-card.png";
import trackGaEvent from "@utils/trackGaEvent";
import { useCallback } from "react";
import { useCheck } from "@context/CheckContext";
import { usePlace } from "@context/PlaceContext";

interface CompleteAdBannerProps {
  show?: boolean;
}

function CompleteAdBanner({ show }: CompleteAdBannerProps) {
  const { cardType } = useCheck().checkoutComplete;
  const { place } = usePlace();

  const amexRegex = /amex|american express/;

  const isAmex = amexRegex.test(cardType?.toLowerCase() ?? "");

  const handleClick = useCallback(() => {
    trackGaEvent("Clicked AMEX Banner", {
      description: `PlaceCode: ${place?.code}`,
    });
  }, [place?.code]);

  if (!show) {
    return <></>;
  }

  return (
    <Styled.Card direction="column">
      <Styled.Cover>
        <Styled.Image
          src={isAmex ? PlatinumCardImg : GoldCardImg}
          alt={`${
            isAmex
              ? "An image of a Platinum Card®"
              : "An image of an American Express Gold Card"
          }`}
        />
        <Styled.Title>
          {isAmex ? "The Platinum Card®" : "American Express Gold Card"}
        </Styled.Title>
      </Styled.Cover>
      <Styled.Copy direction="column">
        <Styled.Message>
          {isAmex ? (
            <strong>
              Global Dining Access is available exclusively on The Platinum Card
            </strong>
          ) : (
            <>
              Earn <strong>75,000 Membership Rewards® points</strong>
            </>
          )}
        </Styled.Message>
        <Styled.Button
          className="cta-button"
          href="#"
          onClick={handleClick}
          target="_blank"
          rel="noreferrer"
        >
          Apply Now
        </Styled.Button>
      </Styled.Copy>
    </Styled.Card>
  );
}

export default CompleteAdBanner;
