import * as Styled from "./VendorTopBar.styles";

import React, { useCallback } from "react";

import { ArrowBack } from "@mui/icons-material";
import Button from "@component/Button/Button";
import { EmptyDiv } from "vendor/resypay/components/VendorTopBar/VendorTopBar.styles";
import VendorIcon from "@vendor/components/VendorLogo/VendorIcon";
import { useNavigate } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

const VendorTopBar = () => {
  const { place } = usePlace();

  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Styled.Wrapper className="overwrite" position="fixed">
        <Styled.Container className="overwrite">
          <Button
            ariaLabel="Go back"
            className="back-btn"
            icon={<ArrowBack fontSize="large" color="inherit" />}
            onClick={handleGoBack}
            size="small"
            variant="text"
          />
          <VendorIcon />
          <EmptyDiv />
        </Styled.Container>
        {place?.name && (
          <Styled.LocationName>
            <h1>{place?.name}</h1>
          </Styled.LocationName>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VendorTopBar;
