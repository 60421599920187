import * as Styled from "./ProceedToCheckout.styles";

import { useCallback, useState } from "react";

import Button from "@component/Button/Button";
import { encodeCheckId } from "@utils/getCheck/getCheck";
import trackGaEvent from "@utils/trackGaEvent";
import { useCheck } from "@context/CheckContext";
import { useNavigate } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

interface ProceedToCheckoutProps {
  onNoTip?: (show: boolean) => void;
}

function ProceedToCheckout({
  onNoTip,
}: ProceedToCheckoutProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { check, selectedTip } = useCheck();
  const { place } = usePlace();

  const navigate = useNavigate();

  const handleCheckout = useCallback(async () => {
    setIsLoading(true);

    if (!selectedTip) {
      setIsLoading(false);
      onNoTip?.(true);
      return;
    }

    trackGaEvent("Clicked Checkout Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });

    check?.number !== undefined &&
      navigate(`/${place?.code}/${encodeCheckId(check.id)}/checkout/`);
  }, [check?.id, check?.number, navigate, onNoTip, place?.code, selectedTip]);

  return (
    <Styled.Container data-testid="ProceedToCheckout" direction="column">
      <Styled.InnerContainer>
        <Button
          isLoading={isLoading}
          onClick={() => {
            void handleCheckout();
          }}
          text="Checkout"
        />
      </Styled.InnerContainer>
    </Styled.Container>
  );
}

export default ProceedToCheckout;
