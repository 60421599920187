import * as Styled from "./VendorFooter.styles";

import VendorLogo from "@vendor/components/VendorLogo/VendorLogo";

export default function VendorFooter(): React.ReactElement {
  const year = new Date().getFullYear();
  return (
    <Styled.Container direction="column">
      <Styled.Logo>
        <VendorLogo />
      </Styled.Logo>
      <span>
        Rooam Pay -{" "}
        <a href="https://rooam.co/" target="_blank" rel="noreferrer">
          Powered by Rooam
        </a>
      </span>

      <span className="text-lighter">© Copyright {year} - Rooam, Inc.</span>

      <span className="text-lighter">
        <a href="https://rooam.co/legal/" target="_blank" rel="noreferrer">
          Rooam Pay Terms
        </a>
      </span>
    </Styled.Container>
  );
}
